import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Accordion, Card } from 'react-bootstrap'

import { siteMetadata } from '../../../gatsby-config'
import Layout from 'components/layout'
import Meta from 'components/meta'

import PMPortrait from '../../../content/images/pm-abiy-2.jpg'
import Poster1Img from '../../../content/images/poster-1.jpg'
import Poster2Img from '../../../content/images/poster-2.jpg'

class WhatIsMedemer extends React.Component {
  render() {
    const { location, data } = this.props
    const profile = get(data, 'profile.childImageSharp.fixed')
    const work1 = get(data, 'work1.childImageSharp.sizes')
    const work2 = get(data, 'work2.childImageSharp.sizes')
    const back1 = get(data, 'back1.childImageSharp.sizes')
    const back2 = get(data, 'back2.childImageSharp.sizes')

    return (
      <Layout location={location}>
        <Meta site={siteMetadata} title="መደመር ምን ማለት ነው?" />

        <div>
          <section className="jumbotron text-left">
            <div className="container">
              <h1 className="jumbotron-heading">መደመር ምን ማለት ነው?</h1>
              <p className="lead text-muted">
                መደመር <dfn title="HyperText Markup Language">(ግስ)</dfn> - መሰብሰብ
                ወይም መመሳሰል
              </p>
            </div>
          </section>

          <div className="container marketing">
            <div className="row featurette">
              <div className="col-md-7 order-md-1">
                <p>
                  መደመር እንደ አንድ ኢትዮጲያ እንድንሰበሰብ ጥሪ ነው። ከዚህ በፊት እንደነበርነው። የግል
                  ልዩነቶቻችን ከሌሎች ጋር ማወሃድ መቻላችን ከትልቁ መብቶቻችን አንዱ ነው። አንድ ሆነን
                  በመሰብሰባችን ለአገራችን እድገት የሚያስፈልጉ ልውጦችን መፍጠር እንችላለን።
                </p>

                <p>
                  በመደመር የምንፈልገውን መልስ ላናገኝ እንችላለን። ይልቅ አብረን ተስማምተን አንድ የጋራ አላማችንን
                  ማሳካት ነው። በኢትዮጲያ ውስጥ የሚገኑ የተለያዩ ሀሳቦች እና አስተያየቶች ማክበር።{' '}
                </p>

                <p>
                  የመደመር ሪፎርም በኢትዮጲያ ጠቅላይ ሚኒስትር ዶክተር ዐቢይ አሕመድ ባቀረቡት መሠረት በሦስት እርስ
                  ተያያዥ አምዶች ላይ ያተኮሩ ናቸው።
                </p>

                <Accordion defaultActiveKey="0">
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                      <h2>ዴሞክራሲ መገንባት</h2>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <p>
                          ዴሞክራሲያዊ እና ብዙሃዊ ዴሞክራሲ መገንባት የዜጎች በመንግስት ላይ ያላቸውን እምነት
                          ለማሳደግ ፈጣን እና ቆራጥ እርምጃዎችን ይጠይቃል ፡፡
                        </p>

                        <p>
                          ላለፉት አስርት ዓመታት የኢትዮጵያ ኢኮኖሚ በየዓመቱ ፈጣን ዕድገት በአማካይ 10%
                          የተመዘገበ ቢሆንም ህብረተሰቡ በሥልጣን ላይ ያለው ደረጃ ላይ ደርሷል ፡፡ የፖለቲካ
                          ነፃነት እና ዴሞክራሲ በመንግስት አጭበርባሪ ዘዴዎች ተደምስሰዋል ፡፡
                        </p>

                        <p>
                          ስለሆነም ጠቅላይ ሚኒስትርሩ የፀረ፡ሽብርተኝነት ሕጉን ጨምሮ ብዙውን ጊዜ ተቃራኒ
                          አመለካከቶችን ለማደመጥ ቃል ገብተዋል፡፡ በተጨማሪም ከ 13,000 በላይ እስረኞች
                          የይቅርታ ዘመቻ አካል ሆነው የተለቀቁ ሲሆን ፓርላማው ሁለት የተቃዋሚ ፓርቲ ቡድኖችን
                          በመለየት ፣ በኦሮሞ ነፃነት ግንባር (ኦነግ) እና ‘በግንቦት 7’ በግዞት በተደረገው
                          ንቅናቄ ነፃ ሆነዋል ፡፡ የተሻሻለው የሲቪል ማህበረሰብ ሕግ በቅርቡ መሻሻል አለበት
                          ፡፡ በሶማሌ ክልል ፕሬዝዳንት አብዲ ሞሃመድ ኡመርም አብዲ ኢሌ በመባልም የሚታወቁት
                          በከባድ የሰብአዊ መብት ጥሰቶች ስለተከሰሱ በዋናነት በተመፀኛው የሊዩ ፖሊስ አዛዥነት
                          ምክንያት ከስልጣን ተባረዋል ፡፡
                        </p>

                        <p>
                          በጾታ ልዩነት ላይ አገሪቱ ዋና ዋና ርዕሶችን አዘጋጅታለች ፡፡ አዲሱ የአቢይ ካቢኔ
                          ግማሽ የሚሆኑት በሴቶች የተሟሉ ናቸው ፣ ፓርላማው ሳህለወርቅ ዘውዴን የቀድሞው
                          የሀገሪቱ ሴት ፕሬዚዳንት በመሆን ሴቶቹ ደግሞ ወደ ጠቅላይ ፍርድ ቤት (መአዛ አሸናፍ)
                          እና የምርጫ ኮሚሽን (ብርቱካን ሚደቅሳ) እየተመሩ ነው፡፡
                        </p>
                        <div>
                          <br />
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="1">
                      <h2>ኢኮኖሚያዊ ጠቀሜታ</h2>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>
                        <p>
                          ኢትዮጵያ ዝቅተኛ ገቢ ሀገር ሲሆን የመላመድ ችሎታው ዝቅ ያለ ነው፡፡ ዋናው ዘርፍ
                          ግብርና ነው (40% GDP እና 70% ቅጥር) ፣ ይህ ማለት አብዛኛው ኢኮኖሚው በአየር
                          ንብረት ላይ የተመሠረተ ነው። ይህንን ተጋላጭነት ከግምት በማስገባት መንግስት በአሁኑ
                          ወቅት የአምራች ኢንዱስትሪውን ለማሳደግ እና አረንጓዴ የአየር ንብረት ተከላካይ
                          የመካከለኛ ገቢ ኢኮኖሚን ​​በ 2018 ለመገንባት የታቀደ ሁለተኛውን የእድገትና
                          ትራንስፎርሜሽን እቅዱን በመተግበር ላይ ይገኛል ፡፡ የመጀመሪያዎቹ አምስት ዓመታት
                          ዕቅድ (GTP I ፡2003-2008) እና የእሱ ተተኪ (GTP II ፡2008-2013)
                          በታላቁ ግዙፍ መሰረተ ልማት ላይ በመመካት አገሪቱን ከእርሻ ወደ ማኑፋክቸሪንግ መሠረት
                          ያደረገ ኢኮኖሚ ለማሸጋገር የተቀየሱ ናቸው ፡ የመጓጓዣ አገናኞችን እና የሃይድሮ
                          ኤሌክትሪክ ኃይልን ጨምሮ ፕሮጀክቶች። የሆነ ሆኖ ፣ ከዚህ በፊት የተቋቋመው የልማት
                          ሞዴል አቅሙ ላይ የደረሰ ይመስላል እናም አዲሱ መንግሥት ኢኮኖሚውን ለማዳን አዲስ
                          ስትራቴጂ በፍጥነት ማጤን ነበረበት ፡፡
                        </p>

                        <p>
                          እንደ ሁለተኛው አፍሪካዊ የስነ ሕዝብ አወቃቀር ኃይል መንግሥት ዜጎች የትምህርት
                          ሥርዓትን በማሻሻል እንዲሁም በወጣቶች ምኞት ላይ ኢንቬስት በማድረግ አቅማቸውን
                          የሚፈፀሙበት በቂ አካባቢ እንዲኖራቸው መንግሥት ማረጋገጥ አለበት ፡፡ የሴቶች እና
                          የወጣቶች ተሳትፎ ሳያካትት ኢትዮጵያ የዓለም አቀፉን ገበያ ማዕከል መድረስ አትችልም፡፡
                          ስለዚህ ተለዋዋጭ ኢኮኖሚ መፍጠር የሚከተሉትን ይጠይቃል-
                        </p>

                        <ul>
                          <li>ለአነስተኛ እና መካከለኛ ኢንተርፕራይዞች ፋይናንስ ማመቻቸት ፣</li>

                          <li>
                            ንግድን ለመጀመር እና የፖሊሲ አካባቢን ለማሻሻል ደንቦችን ማውጣት (የተቋማት
                            ማሻሻያዎች ፣ የንግድ እና የኢንቨስትመንት ኮዶች እና ሌሎች የንግድ ህጎች) ፣
                          </li>

                          <li>
                            የግሉ ሴክተር የኢኮኖሚው ዋና ክፍል ማድረግ እና ለአለም አቀፍ ንግድ መክፈት
                            (በመንግሥት ባለቤትነት የተያዙ ድርጅቶች አናሳ ንግዶች ሽያጭ እና የህዝብ ወጪ)።
                          </li>
                        </ul>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="2">
                      <h2>መንግሥት እና የግል አጋርነት</h2>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="2">
                      <Card.Body>
                        <p>
                          እንዲሁም ሀገራት ኢኮኖሚያቸውን ከቀዳሚ ምርቶች በላይ እንዲያሳድጉ እና ማህበራዊና
                          ኢኮኖሚያዊ እድገታቸውን ለማፋጠን ሊረዳ የሚችል ሌላ ዘርፍ ማጎልበት ለአህጉሪቱ መጪው
                          ጊዜ አስፈላጊ ነው ፡፡ ከዚህ አንፃር የአብይ አዲስ አስተዳደር የተሻሻለ አካባቢያዊ
                          ውህደትን ያበረታታል ፡፡
                        </p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="3">
                      <h2>ክልላዊ ውህደት እና ለአለም ክፍት መሆን</h2>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="3">
                      <Card.Body>
                        <p>
                          እርስ በርስ መደጋገፍ በጎረቤቶች መካከል የግጭት የመከሰት እድልን ይቀንሳል። ዶ / ር
                          አህመድ የሚከተሉትን ሀረግ በመጠቀም ሀሳቡን ለመግለጽ “በፍጥነት መሄድ ከፈለጉ ለብቻዎ
                          ይሂዱ ፣ ግን በጣም ሩቅ መሄድ የሚፈልጉ ከሆነ አብረው ይሂዱ።” በእውነቱ በኢትዮጵያ
                          የብዝሃ፡አቀራረብ አካሄድ ውስጥ ይንፀባርቃል ፣ በተለይም አህጉራዊ ነፃ የንግድ
                          ስምምነትን ሲቀላቀል (CFTA) እና በተለያዩ መንግስታዊ እና አህጉራዊ ተቋማት ውስጥ
                          የበይነ፡መንግስታዊ ልማት ባለስልጣን (ኢጋድ)4 ፣ የምስራቅ እና የደቡብ አፍሪካ የጋራ
                          ገበያ (ኮሜሳ)5 ወይም በአፍሪካ ህብረት (አሕ) ውስጥ ንቁ ተሳትፎ በማድረግ ነው ፡፡
                          የሰላም መንግስታት የአገሮችን እና የዜጎቻቸውን ደህንነት አደጋ ላይ ለሚጥሉ የወንጀል
                          ተግባራት አነስተኛ ምቹ ሁኔታን ይፈጥራል ፡፡ አገሪቱ ለረጅም ጊዜ የዘለቀችው የነርቭ
                          ስርዓት ኤርትራን ያስመዘገበችው አስገራሚ አለመግባባት በክልሉ ውስጥ የሰላም ቀበቶ
                          እንዲፈጠር የሚያስችል አጋጣሚ ከፍቷል ፣ ይህም በምዕራብ በኩል ወደ ምስራቅ መሮጥ እና
                          አህጉሩን የሚያቋርጥ የትብብር መንገድን ለመፍጠር ያስችላል ፡፡
                        </p>
                        <p>
                          ከዚህ በተጨማሪም ኢትዮጵያ እና ጎረቤቶ ስይን ህጎች እና ሀብቶችን ለማዳን መንገዶችን
                          በመፈለግ ለዓለም ክፍት የመሆን እድላቸውን ከፍ ሊያደርጉ ይችላሉ ፡፡ ዶ / ር አህመድ
                          ሁሉንም የኢጋድ አገሮችን የሚወክል አንድ ዲፕሎማሲያዊ ቦታን በመወከል ወደፊት በመገኘት
                          በልማት ጉዳዮች ላይ የተሻለ የትብብር ቅንጅት እንዲኖር ያስችላል ፡፡
                        </p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
              <div className="col-md-5 order-md-2">
                <img src={Poster1Img} width="100%" className="img-responsive" />
                <br />
                <img src={Poster2Img} width="100%" className="img-responsive" />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default WhatIsMedemer

export const query = graphql`
  query AmWhatIsMedemerPageQuery {
    profile: file(name: { eq: "profile" }) {
      childImageSharp {
        fixed(width: 120, height: 120) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    work1: file(name: { eq: "work1" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    work2: file(name: { eq: "work2" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    work3: file(name: { eq: "work3" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    back1: file(name: { eq: "back1" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    back2: file(name: { eq: "back2" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
  }
`
